import React from "react";
import { Link } from "react-router-dom";

const ProductUpdatesTable = ({ tableData, isLoading }) => {
  // console.log(tableData, "table data check");

  const skeletonRows = 1;
  const skeletonArr = new Array(skeletonRows).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  const skeleton = (
    <tbody>
      {skeletonArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-5 ps-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-5">
            {item}
          </th>
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      <div className="table-responsive">
        <table className="table mt-3 table-tb">
          <thead className="">
            <tr className="rounded">
              <th className="tr-tt fw-bold ps-5" scope="col">
                #
              </th>
              <th className="tr-tt fw-bold" scope="col">
                UUId
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Created
              </th>
            </tr>
          </thead>
          {isLoading ? (
            skeleton
          ) : (
            <tbody>
              {tableData.length === 0 ? (
                <th colSpan={14} className="py-4 ps-5 align-middle">
                  No Product Updates data found
                </th>
              ) : (
                tableData.map((item, index) => (
                  <tr key={item.id}>
                    <th className="py-4 ps-5 align-middle" scope="row">
                      {index + 1}
                    </th>
                    <th className="py-4 align-middle">
                      {item.uuid.length >= 20 ? (
                        <Link
                          title={item.uuid}
                          to={`/productUpdates/${item.uuid}`}
                        >
                          {item.uuid.substring(0, 20)}...
                        </Link>
                      ) : (
                        <Link
                          title={item.uuid}
                          to={`/productUpdates/${item.uuid}`}
                        >
                          {item.uuid}
                        </Link>
                      )}
                    </th>
                    <th className="py-4 align-middle">
                      {item.created_at === null
                        ? "N/A"
                        : new Date(item.created_at).toLocaleString()}
                    </th>
                  </tr>
                ))
              )}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default ProductUpdatesTable;
