import ExistingModal from "../../components/modals/ExistingModal";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { app_url } from "../../config";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import axios from "axios";
import AddNetworkCoverageModal from "../../components/modals/AddNetworkCoverageModal";
import DeleteNetworkCoverageModal from "../../components/modals/DeleteNetworkCoverageModal";

const Global = () => {
  const { uid } = useParams();
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [existing, setExisting] = useState(null);
  const [nonExisting, setNonExisting] = useState(null);
  const [removedCountries, setRemovedCountries] = useState([]);
  const [addCountries, setAddCountries] = useState([]);
  const [res, setRes] = useState(null);
  const [imgUpdate, setImageUpdated] = useState("");
  const [online, setOnline] = useState(navigator.onLine);
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedPlanType, setSelectedPlanType] = useState("");
  const [planTypeData, setPlanTypeData] = useState([]);
  const [networkCoverages, setNetworkCoverages] = useState([]);
  const [countryCoverages, setCountryCoverages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalPlanTypeLoading, setModalPlanTypeLoading] = useState(false);
  const [selectedNetworkCoverage, setSelectedNetworkCoverage] = useState("");
  const [networkCoverageDetails, setNetworkCoverageDetails] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteNetworkCoverageId, setDeleteNetworkCoverageId] = useState(null);
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;

  // Router
  const navigate = useNavigate();

  // Handle the save button
  const handleSave = (e, key) => {
    switch (key) {
      case "Flag Image":
        const file = e.target.files[0];
        if (file?.size > 1000000) {
          toast.error("Image Size Should be Less Then 1MB");
          // setCardImg(app_url + PkgDetail.cardImage)
        } else if (file) {
          let imgData = new FormData();
          imgData.append("uid", uid);
          imgData.append("slug", "region");
          imgData.append("countryImage", file);
          setLoading(true);
          axios
            .post(
              `${app_url}/api/country/update_country_region_imageById`,
              imgData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              toast.success(res.data.message);
              setImageUpdated(imgData);
              setLoading(false);
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                navigate("/");
              } else if (err.response?.status === 400) {
                toast.error(err.response?.data.message);
                setLoading(false);
              } else if (err.response?.status === 500) {
                setOnline(false);
                setLoading(false);
              } else {
                setOnline(navigator.onLine);
                setLoading(false);
              }
            });
        }
        break;
      case "Starting Price":
        setLoading(true);
        axios
          .post(
            app_url +
              `/api/country/update_country_or_region_sp_price_by_id?uid=${uid}&c_r_slug=${"region"}&s_p_slug=${"starting_price"}&price=${
                apiData[0]?.startingFrom
              }`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success(res.data.message);
            setLoading(false);
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              navigate("/");
            } else if (err.response?.status === 400) {
              toast.error(err.response?.data.message);
              setLoading(false);
            } else if (err.response?.status === 500) {
              setOnline(false);
              setLoading(false);
            } else {
              setOnline(navigator.onLine);
              setLoading(false);
            }
          });
        break;
      case "Previous Price":
        setLoading(true);
        axios
          .post(
            app_url +
              `/api/country/update_country_or_region_sp_price_by_id?uid=${uid}&c_r_slug=${"region"}&s_p_slug=${"previous_price"}&price=${
                apiData[0]?.previousPrice
              }`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success(res.data.message);
            setLoading(false);
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              navigate("/");
            } else if (err.response?.status === 400) {
              toast.error(err.response?.data.message);
              setLoading(false);
            } else if (err.response?.status === 500) {
              setOnline(false);
              setLoading(false);
            } else {
              setOnline(navigator.onLine);
              setLoading(false);
            }
          });
      default:
        break;
    }
  };

  const AddCountries = (slug) => {
    setLoading(true);
    const dataToSend = { slug: slug, uid: uid, data: addCountries };
    if (addCountries.length === 0) {
      toast.error("Please Add Country");
      return;
    }
    axios
      .post(
        app_url +
          "/api/country/remove_or_add_local_countries_for_global_by_array",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAddCountries([]);
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data.message);
          setLoading(false);
        } else if (err.response?.status === 500) {
          setOnline(false);
          setLoading(false);
        } else {
          setOnline(navigator.onLine);
          setLoading(false);
        }
      });
  };

  const removeCountries = (slug) => {
    if (removedCountries.length === 0) {
      toast.error("Please Select Countries to remove");
      return;
    }
    setLoading(true);
    const dataToSend = { slug: slug, uid: uid, data: removedCountries };
    axios
      .post(
        app_url +
          "/api/country/remove_or_add_local_countries_for_global_by_array",
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setRemovedCountries([]);
        window.location.reload();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data.message);
          setLoading(false);
        } else if (err.response?.status === 500) {
          setOnline(false);
          setLoading(false);
        } else {
          setOnline(navigator.onLine);
          setLoading(false);
        }
      });
  };

  // Get the Selected Country
  useEffect(() => {
    setLoading(true);
    const url = app_url + `/api/country/getGlobalCountries`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setApiData(res.data?.region);
        setCarriers(res?.data?.carriers);
        setCountryCoverages(res?.data?.coverages);
        setRes(res.data);
        setExisting(res.data?.existedLocals);
        setNonExisting(res.data?.nonExistingLocals);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data.message);
          setLoading(false);
        } else if (err.response?.status === 500) {
          setOnline(false);
          setLoading(false);
        } else {
          setOnline(navigator.onLine);
          setLoading(false);
        }
      });
  }, []);

  const getNetworkCoverage = (selectedPlanType) => {
    setModalLoading(true);
    const url =
      app_url +
      `/api/country/getCoveragesFromCarrierProvider?carrierProviderId=${
        carriers[0]?.id
      }&slug=${"global"}&countryCode=${""}&plan_type=${selectedPlanType}&regionSlug=${""}`;
    // console.log("Request URL:", url);
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setNetworkCoverages(res?.data?.coverages);
        setModalLoading(false);
      })
      .catch((err) => {
        setModalLoading(false);
        if (err.response) {
          const { status, data } = err.response;
          if (status === 401) {
            navigate("/");
          } else if (status === 400) {
            toast.error(data?.message);
          } else if (status === 500) {
            toast.error(data?.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  const getPlanType = () => {
    setModalPlanTypeLoading(true);
    const url =
      app_url +
      `/api/country/getCoveragesTypeFromCarrierProvider?carrierProviderId=${
        carriers[0]?.id
      }&slug=${"global"}&countryCode=${""}&regionSlug=${""}`;
    // console.log("Plan Type Requeast Url:", url);
    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setPlanTypeData(res?.data?.plans);
        // console.log(res?.data?.plans, "plan type data check");
        setNetworkCoverageDetails(null);
        setModalPlanTypeLoading(false);
      })
      .catch((err) => {
        setModalPlanTypeLoading(false);
        if (err.response) {
          const { status, data } = err.response;
          if (status === 401) {
            navigate("/");
          } else if (status === 400) {
            toast.error(data?.message);
          } else if (status === 500) {
            setModalLoading(false);
            toast.error(data?.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  // console.log(modalLoading, "check modal loading");
  // function to set selected network: countryCode, planType and networkName
  const handleNetworkCoverageChange = (e) => {
    const selectedNetworkName = e.target.value;
    setSelectedNetworkCoverage(selectedNetworkName);

    let selectedCoverage = null;
    let selectedNetwork = null;

    networkCoverages.forEach((coverage) => {
      const network = coverage.networks.find(
        (net) => net.name === selectedNetworkName
      );
      if (network) {
        selectedCoverage = coverage;
        selectedNetwork = network;
      }
    });

    if (selectedCoverage && selectedNetwork) {
      setNetworkCoverageDetails({
        networkName: selectedNetworkName,
        country_code: selectedCoverage.name,
        networkTypes: selectedNetwork.types,
      });
    }
  };

  // console.log(
  //   networkCoverageDetails,
  //   "selected Network Coverage Details check"
  // );

  // Function to handle modal close
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to handle modal open
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCarrier("");
    setSelectedPlanType("");
    setNetworkCoverages([]);
    setSelectedNetworkCoverage("");
    setNetworkCoverageDetails(null);
  };

  const confirmDelete = () => {
    axios
      .delete(
        `${app_url}/api/country/remove_nc_by_id?id=${deleteNetworkCoverageId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setShowDeleteModal(false);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/");
        } else if (error.response?.status === 400) {
          toast.error(error.response?.data.message);
        } else if (error.response?.status === 500) {
          toast.error(error.response?.data.message);
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  const handleDelete = (id) => {
    // console.log("Deleting ID:", id);
    setDeleteNetworkCoverageId(id);
    setShowDeleteModal(true);
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  return (
    <section className="wrapper d-flex align-content-center justify-content-center">
      <div className="row w-100 justify-content-center">
        {/* {loading ? (
          <div className="bg-transparent d-flex align-items-center">
            <Loader />
          </div>
        ) : ( */}
        <div className="col-lg-12 col-xl-8 col-md-10 col-sm-12">
          <div className="bg-white rounded p-4">
            <form style={{ marginBottom: "2em" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-4 d-flex align-items-center justify-content-between">
                    <h1>Global</h1>
                    <button
                      type="button"
                      className="btn border"
                      data-bs-toggle="modal"
                      data-bs-target="#scrollableModal"
                    >
                      {existing?.length === 1
                        ? existing?.length + " " + "Country"
                        : existing?.length + " " + "Countries"}
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <Select
                    isMulti
                    name="colors"
                    options={existing}
                    placeholder="Remove supported countries"
                    className="basic-multi-select mt-3 mb-4 mb-md-0"
                    classNamePrefix="select"
                    onChange={(Selected) => {
                      setRemovedCountries(Selected);
                    }}
                  />
                  <button
                    disabled={removedCountries.length === 0}
                    className={`${
                      removedCountries.length === 0 ? "d-none" : "d-block"
                    } btn btn-sm bg-danger text-white shadow rounded  my-3`}
                    onClick={() => {
                      removeCountries("removed");
                    }}
                  >
                    Click to Remove Selected
                  </button>
                </div>
                <div className="col-md-6">
                  <Select
                    isMulti
                    name="colors"
                    options={nonExisting}
                    placeholder="Add supported countries"
                    className="basic-multi-select mt-3 mb-4 mb-md-0"
                    classNamePrefix="select"
                    onChange={(Selected) => {
                      setAddCountries(Selected);
                    }}
                  />
                  <button
                    disabled={addCountries.length === 0}
                    className={`${
                      addCountries.length === 0 ? "d-none" : "d-block"
                    } btn btn-sm bg-success text-white shadow rounded my-3`}
                    onClick={() => AddCountries("add")}
                  >
                    Click to Add Selected
                  </button>
                </div>
              </div>
            </form>
            <hr
              className="border-top-2 border-bottom-2"
              style={{ padding: "8px" }}
            />
            {loading ? (
              <div className="bg-transparent d-flex align-items-center">
                <Loader />
              </div>
            ) : countryCoverages?.length === 0 ? (
              <div>No Data Found</div>
            ) : (
              <table className=" table table-bordered">
                <thead className="">
                  <tr>
                    <th>Country</th>
                    <th>Plan Type</th>
                    <th>Network Name</th>
                    <th>Network Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {countryCoverages.map((coverage, index) => (
                    <React.Fragment key={index}>
                      {coverage.networks?.map((item, networkIndex) => (
                        <tr key={networkIndex}>
                          {networkIndex === 0 && (
                            <td
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                              rowSpan={coverage.networks.length}
                            >
                              {coverage.country_code}
                            </td>
                          )}
                          <td>{item.plan_type}</td>
                          <td>{item.network_name}</td>
                          <td>{JSON.parse(item.network_types).join(", ")}</td>
                          <td style={{ color: "red" }}>
                            <i
                              className="bi bi-trash text-danger fs-5 d-flex"
                              onClick={() => handleDelete(item.id)}
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
            <div
              style={{
                marginTop: "2em",
                justifyContent: "right",
                display: "flex",
              }}
            >
              <button className="btn card-btn" onClick={handleOpenModal}>
                Add Network Coverage
              </button>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
      <ExistingModal data={existing} />
      <AddNetworkCoverageModal
        setSelectedCarrier={setSelectedCarrier}
        carriers={carriers}
        selectedCarrier={selectedCarrier}
        setSelectedPlanType={setSelectedPlanType}
        getNetworkCoverage={getNetworkCoverage}
        selectedPlanType={selectedPlanType}
        networkCoverages={networkCoverages}
        show={showModal}
        onHide={handleCloseModal}
        loading={modalLoading}
        selectedNetworkCoverage={selectedNetworkCoverage}
        setSelectedNetworkCoverage={setSelectedNetworkCoverage}
        getPlanType={getPlanType}
        planTypeData={planTypeData}
        modalPlanTypeLoading={modalPlanTypeLoading}
        handleNetworkCoverageChange={handleNetworkCoverageChange}
        networkCoverageDetails={networkCoverageDetails}
      />
      <DeleteNetworkCoverageModal
        show={showDeleteModal}
        onHide={cancelDelete}
        onConfirm={confirmDelete}
      />
    </section>
  );
};

export default Global;
