import { configureStore } from '@reduxjs/toolkit'
import authReducer from './Authreducer';
import UserDeatilSlice from './UserDeatilSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userToken: UserDeatilSlice
  },
})
// import { combineReducers, createStore } from 'redux';


// const reduxstore = combineReducers({
//   auth: authReducer,
// });
// const store = createStore(reduxstore);

// export default reduxstore;