import React from "react";
import ExcelJS from "exceljs";

const ProductUpdatesDetailModal = ({ data, columns, onClose }) => {
  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Product Updates");

    // headers with bold style for the first row
    const headerRow = worksheet.addRow(
      columns.map((col) => col.replace(/_/g, " "))
    );
    headerRow.font = { bold: true };

    // data
    data.forEach((item) => {
      worksheet.addRow(columns.map((col) => item[col] || ""));
    });

    // Save workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `product_updates ${data.length}.xlsx`;
      a.click();
      onClose(); // Close modal after export
    });
  };

  return (
    <>
      <div
        className="modal fade show"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable ">
          <div className="modal-content">
            <div className="modal-header py-3 px-4">
              <h1 className="modal-title fs-2" id="exampleModalLabel">
                Details
              </h1>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button> */}
            </div>
            <div className="modal-body p-0">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {columns.map((col, index) => (
                      <th align="middle" key={index}>
                        {col?.replace(/_/g, " ")}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      {columns.map((column) => (
                        <td key={column}>{item[column]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary excel-btn"
                onClick={exportToExcel}
              >
                Export to Excel
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductUpdatesDetailModal;
