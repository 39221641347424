import React, { useEffect, useRef, useState } from "react";
import Loader from "../Loader";
import { truncateText } from "../../utils/truncateText";
import { FaEye, FaCalendarAlt, FaStop, FaTrashAlt } from "react-icons/fa";
import { app_url } from "../../config";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Error500 from "../500/Error500";
import sucessIcon from "@/../../public/assets/images/checked.png";
import failureIcon from "@/../../public/assets/images/cancel.png";

const CampaignsTable = ({ campaignData, isLoading }) => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [online, setOnline] = useState(navigator.onLine);
  const [showModal, setShowModal] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [selectedCampaignName, setSelectedCampaignName] = useState(null);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [launchingTime, setLaunchingTime] = useState("");
  const launchingTimeRef = useRef(null);

  useEffect(() => {
    const now = new Date();

    // Add 61 minutes to the current time
    const tenMinutesLater = new Date(now.getTime() + 61 * 60000);

    // Format the date and time as required for datetime-local input
    const formatDateTime = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    // Set the min attribute
    const minDateTime = formatDateTime(tenMinutesLater);
    document.getElementById("launchingTime")?.setAttribute("min", minDateTime);
  }, [launchingTimeRef.current]);

  const stopCampaign = (id) => {
    setCampaignLoading(true);
    axios
      .post(
        `${app_url}/api/notification/stopFcmPushTemplateCampaign`,
        { id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setCampaignLoading(false);
        toast.success(res?.data?.message);
        handleClose();
        window.location.reload();
      })
      .catch((err) => handleError(err));
  };

  const deleteCampaign = (id) => {
    setCampaignLoading(true);
    axios
      .post(
        `${app_url}/api/notification/deleteFcmPushTemplateCampaign`,
        { id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setCampaignLoading(false);
        toast.success(res?.data?.message);
        handleDeleteClose();
        window.location.reload();
      })
      .catch((err) => handleError(err));
  };

  const RescheduleCampaign = (id, launchingTime) => {
    setCampaignLoading(true);
    axios
      .post(
        `${app_url}/api/notification/resheduleFcmPushTemplateCampaign`,
        { id, launchingTime },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setCampaignLoading(false);
        toast.success(res?.data?.message);
        handleRescheduleClose();
        window.location.reload();
      })
      .catch((err) => handleError(err));
  };

  const handleError = (err) => {
    // console.log(err, "err check");
    if (err?.response?.status === 401) {
      navigate("/");
    } else if (err?.response?.status === 400 || err?.response?.status === 500) {
      toast.error(err?.response?.data?.message);
      setErrMsg(err?.response?.data?.message);
    } else {
      setErrMsg(err?.response?.data?.message);
      toast.error(err?.response?.data?.message);
    }
    setCampaignLoading(false);
    setOnline(navigator.onLine);
  };

  const handleShow = (id, campaignName) => {
    setSelectedCampaignId(id);
    setSelectedCampaignName(campaignName);
    setShowModal(true);
  };

  const handleShowReschedule = (id, campaignName, launch_date) => {
    setSelectedCampaignId(id);
    setSelectedCampaignName(campaignName);
    setLaunchingTime(new Date(launch_date).toISOString().slice(0, 16));
    setShowRescheduleModal(true);
  };

  const handleShowDelete = (id, campaignName) => {
    // New function to show delete modal
    setSelectedCampaignId(id);
    setSelectedCampaignName(campaignName);
    setShowDeleteModal(true);
  };

  const handleStopCampaign = () => {
    if (selectedCampaignId) {
      stopCampaign(selectedCampaignId);
    }
  };

  const handleRescheduleCampaign = () => {
    if (selectedCampaignId && launchingTime) {
      RescheduleCampaign(selectedCampaignId, launchingTime);
    }
  };

  const handleDeleteCampaign = () => {
    // New function to handle delete campaign
    if (selectedCampaignId) {
      deleteCampaign(selectedCampaignId);
    }
  };

  const handleClose = () => setShowModal(false);
  const handleRescheduleClose = () => setShowRescheduleModal(false);
  const handleDeleteClose = () => setShowDeleteModal(false);

  const skeletonRows = 1;
  const skeletonArr = new Array(skeletonRows).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  const skeleton = (
    <tbody>
      {skeletonArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-4 ps-3">
            {item}
          </th>
          <th scope="row" className="py-4">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-4">
            {item}
          </th>
          <th scope="row" className="py-4">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-4">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-4">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-4">
            {item}
          </th>
        </tr>
      ))}
    </tbody>
  );

  if (!online) {
    return <Error500 message={errMsg} />;
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table mt-4 table-tb">
          <thead className="">
            <tr className="rounded">
              <th className="tr-tt fw-bold ps-3" scope="col">
                Campaign Name
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Template
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Category
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Users
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Launch Date & Time
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Status
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Action
              </th>
            </tr>
          </thead>

          {isLoading ? (
            skeleton
          ) : (
            <tbody>
              {campaignData?.result?.length === 0 ? (
                <th colSpan={14} className="py-4 ps-3 align-middle text-center">
                  No Campaigns data found!
                </th>
              ) : (
                campaignData?.result?.map((item, index) => {
                  const statusStyle = {
                    completed: { color: "green", fontWeight: "bold" },
                    stop: { color: "red", fontWeight: "bold" },
                    scheduled: { color: "#F57C21", fontWeight: "bold" },
                    default: { color: "black", fontWeight: "normal" },
                  };

                  const getStatusStyle = (status) => {
                    switch (status) {
                      case "completed":
                        return statusStyle.completed;
                      case "stop":
                        return statusStyle.stop;
                      case "scheduled":
                        return statusStyle.scheduled;
                      default:
                        return statusStyle.default;
                    }
                  };
                  return (
                    <>
                      <tr key={index}>
                        <th className="ps-3 py-4 align-middle">
                          {truncateText(item?.campaign_name, 25)}
                        </th>
                        <th className="py-4 align-middle">
                          {truncateText(item?.template_name, 25)}
                        </th>
                        <th className="py-4 align-middle">
                          {item?.push_type?.replace(/_/g, " ")}
                        </th>
                        <th className="py-4 align-middle">
                          {item?.push_type === "Predefined"
                            ? item?.predefined_id === 1
                              ? "All Mobile App Users"
                              : item?.predefined_id === 2
                              ? "Registered Users"
                              : item?.predefined_id === 3
                              ? "Unregistered Users"
                              : item?.predefined_id === 4
                              ? "Inactive eSIM Users"
                              : item?.predefined_id === 5
                              ? "active eSIM Users"
                              : item?.predefined_id === 6
                              ? "Finished eSIM Users"
                              : item?.predefined_id === 7
                              ? "Expired eSIM Users"
                              : ""
                            : item?.push_type === "Group"
                            ? item?.group_name
                            : item?.push_type === "By_Country"
                            ? item?.country_code
                            : item?.push_type === "Custom"
                            ? item?.custom_recipients
                              ? `${
                                  JSON.parse(item?.custom_recipients).length
                                } users`
                              : "-"
                            : "-"}
                        </th>

                        <th className="py-4 align-middle">
                          {new Date(item?.launch_date).toLocaleString()}
                        </th>
                        <th
                          // style={{
                          //   color:
                          //     item.status === "completed"
                          //       ? "green !important"
                          //       : item.status === "stop"
                          //       ? "red"
                          //       : item.status === "scheduled"
                          //       ? "orange"
                          //       : "black",
                          // }}
                          // style={getStatusStyle(item.status)}
                          className="py-4 align-middle"
                        >
                          <p
                            style={{
                              ...getStatusStyle(item.status),
                              marginBottom: "0",
                            }}
                          >
                            {item.status}
                          </p>
                          {item?.status === "completed" ? (
                            <>
                              <div className="d-flex flex-column  ">
                                <div className="">
                                  <img
                                    src={sucessIcon}
                                    alt="success icon"
                                    className="me-1"
                                  />
                                  {item?.success_count}
                                </div>
                                <div className="">
                                  <img
                                    src={failureIcon}
                                    alt="failure icon"
                                    className="me-1"
                                  />
                                  {item?.failure_count}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </th>
                        <th className=" py-4 align-middle">
                          {item?.status === "scheduled" ? (
                            <>
                              <FaEye
                                className="me-2 pointer"
                                title="View"
                                size={16}
                              />

                              <FaStop
                                className="me-2 pointer"
                                title="Stop"
                                color="red"
                                size={16}
                                onClick={() =>
                                  handleShow(item?.id, item?.campaign_name)
                                }
                              />
                            </>
                          ) : item?.status === "stop" ? (
                            <>
                              <FaEye
                                className="me-2 pointer"
                                title="View"
                                size={16}
                              />
                              <FaCalendarAlt
                                className="me-2 pointer"
                                title="Reschedule"
                                size={16}
                                onClick={() =>
                                  handleShowReschedule(
                                    item?.id,
                                    item?.campaign_name,
                                    item?.launch_date
                                  )
                                }
                              />
                              <FaTrashAlt
                                className="me-2 pointer"
                                title="Delete"
                                color="red"
                                size={16}
                                onClick={() => {
                                  handleShowDelete(
                                    item?.id,
                                    item?.campaign_name
                                  );
                                }}
                              />
                            </>
                          ) : item?.status === "completed" ? (
                            <>
                              <FaEye
                                className="me-2 pointer"
                                title="View"
                                size={16}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </th>
                      </tr>
                    </>
                  );
                })
              )}
            </tbody>
          )}
        </table>
      </div>

      {/* stop campaign modal */}
      {showModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            {/* Stop Campaign Modal Content */}
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Stop Campaign</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                  ></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to stop the campaign{" "}
                  <span className="fw-bold">{selectedCampaignName}</span>?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleStopCampaign}
                  >
                    Confirm
                    {campaignLoading ? (
                      <span
                        className="spinner-border spinner-border-sm text-light"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Reschedule campaign modal */}
      {showRescheduleModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            {/* Reschedule Campaign Modal Content */}
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Reschedule Campaign</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleRescheduleClose}
                  ></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to Reschedule the campaign{" "}
                  <span className="fw-bold">{selectedCampaignName}</span>?
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleRescheduleCampaign();
                    }}
                  >
                    <div className="my-3">
                      <label htmlFor="launchingTime" className="form-label">
                        Launching Time
                      </label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        id="launchingTime"
                        value={launchingTime}
                        onChange={(e) => setLaunchingTime(e.target.value)}
                        ref={launchingTimeRef}
                        required
                      />
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-primary">
                        Confirm
                        {campaignLoading ? (
                          <span
                            className="spinner-border spinner-border-sm text-light"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* delete campaign modal */}
      {showDeleteModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
              {/* Delete Campaign Modal Content */}
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Delete Campaign</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleDeleteClose}
                  ></button>
                </div>
                <div className="modal-body">
                  Are you sure you want to delete the campaign{" "}
                  <span className="fw-bold">{selectedCampaignName}</span>?
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDeleteCampaign}
                  >
                    Delete
                    {campaignLoading ? (
                      <span
                        className="spinner-border spinner-border-sm text-light"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CampaignsTable;
