import React, { useCallback, useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import EsimTable from "../../components/tables/EsimTable";
import axios from "axios";
import { app_url } from "../../config";
import _ from "lodash";
import { formatDate } from "../../formatDate/formatDate";
import { addDays, setHours, setMinutes, setSeconds } from "date-fns";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Error500 from "../../components/500/Error500";
import Pagination from "../../components/tables/Pagination";

const ESims = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);

  const toggleDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  function getLastDayOfMonth(year, month) {
    const nextMonth = month + 1;
    const date = new Date(year, nextMonth, 0);
    return date.getDate();
  }

  const month = new Date().getMonth();
  const year = new Date().getFullYear();

  const getStartDateForInitialState = () => {
    const actualDate = addDays(new Date(), +1 - new Date().getDate());
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(actualDate, 0), 0),
      0
    );
    return adjustedStartDate;
  };

  const [state, setState] = useState([
    {
      startDate: getStartDateForInitialState(),
      endDate: new Date(year, month, getLastDayOfMonth(year, month)),
      key: "selection",
    },
  ]);

  //update dateFrom and dateTo based on state changes
  useEffect(() => {
    const formattedStartDate = formatDate(state[0].startDate);
    const formattedEndDate = formatDate(state[0].endDate);

    setDateFrom(formattedStartDate);
    setdateTo(formattedEndDate);
  }, [state]);
  /* Date Range Picker end*/

  // Date Picker outside click handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /* new Date Picker Data */
  const handleSelect = (ranges) => {
    setCurrentPage(1);
    const { startDate, endDate } = ranges.selection;
    const adjustedEndDate = setSeconds(
      setMinutes(setHours(endDate, 23), 59),
      59
    );
    const adjustedStartDate = setSeconds(
      setMinutes(setHours(startDate, 0), 0),
      0
    );
    setState([
      {
        startDate: adjustedStartDate,
        endDate: adjustedEndDate,
        key: "selection",
      },
    ]);
  };

  const navigate = useNavigate();
  const [tabledata, setTabledata] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataOnPage, setDataOnPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = dataOnPage;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [dateFrom, setDateFrom] = useState(formatDate(state[0].startDate));
  const [dateTo, setdateTo] = useState(formatDate(state[0].endDate));
  const [networkIssue, setNetworkIssue] = useState(navigator.onLine);
  const [failed, setFailed] = useState("");

  // fetch eSims
  const fetcheSims = useCallback(
    _.debounce((search) => {
      setLoading(true);
      axios
        .get(
          `${app_url}/api/line/getAlleSIMsForAPortal?search=${search}&numPerPage=${dataOnPage}&from=${dateFrom}&to=${dateTo}&page=${
            currentPage - 1
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (itemsPerPage > tabledata?.data?.pagination?.dataCount) {
            setCurrentPage(1);
          }
          setTabledata(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 401) {
            navigate("/");
          } else if (err.response?.status === 400) {
            toast.error(err.response?.data?.message);
            setLoading(false);
          } else {
            setNetworkIssue(navigator.onLine);
            setFailed(err.message);
            setLoading(false);
          }
        });
    }, 500),
    [dateFrom, dateTo, dataOnPage, currentPage]
  );

  useEffect(() => {
    fetcheSims(search);
  }, [fetcheSims, search]);

  // ================= Search Pegination ===================
  const searchData = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // ================= Handl Pagination Page ===================
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (!networkIssue) {
    return <Error500 message={failed} />;
  }

  return (
    <section className="wrapper">
      {
        <>
          <div className="d-flex flex-wrap gap-3 justify-content-between">
            <form className="d-flex flex-wrap gap-3">
              <input
                style={{ width: 300 }}
                className="form-control"
                type="search"
                placeholder="Search by ICCID, Email or User Name"
                aria-label="Search"
                value={search}
                onChange={searchData}
              ></input>
              <div
                className="dropdown d-flex flex-wrap gap-3"
                ref={datePickerRef}
              >
                <button
                  className="btn btn-white border dropdown-toggle"
                  type="button"
                  // data-bs-toggle="dropdown"
                  // aria-expanded="false"
                  // id="dropdownMenuClickableInside"
                  //  data-bs-auto-close="outside"
                  onClick={toggleDatePicker}
                >
                  Select Date
                </button>
                <div className="d-flex align-items-center gap-1 dropdown">
                  <button
                    type="button"
                    disabled
                    className="text-black btn btn-white border "
                  >
                    {dateFrom}
                  </button>
                  -
                  <button
                    type="button"
                    disabled
                    className="text-black btn btn-white border "
                  >
                    {dateTo}
                  </button>
                </div>
                {showDatePicker && (
                  <ul
                    className="dropdown-menu px-2 py-0 show"
                    style={{ top: "37px", left: "-319px" }}
                  >
                    <li className="my-2 dropdown-item">
                      <DateRangePicker
                        onChange={handleSelect}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                      />
                    </li>
                  </ul>
                )}
                {/* <ul
                  aria-labelledby="dropdownMenuClickableInside"
                  className="dropdown-menu px-2 py-0"
                >
                  <li className="my-2 dropdown-item">
                    <DateRangePicker
                      onChange={handleSelect}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      direction="horizontal"
                    />
                  </li>
                </ul> */}
              </div>
            </form>
          </div>
          <EsimTable tabledata={tabledata} isLoading={loading} />

          <Pagination
            dataOnPage={dataOnPage}
            currentPage={currentPage}
            totalPages={Math.ceil(tabledata?.data?.pagiantion.pages)}
            tabledata={tabledata}
            onPageChange={handlePageChange}
            indexOfFirstItem={indexOfFirstItem}
            itemsPerPage={itemsPerPage}
            indexOfLastItem={indexOfLastItem}
          />
        </>
      }
    </section>
  );
};

export default ESims;
