import { da } from "date-fns/locale";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { app_url } from "../../../config";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader";
import AddNetworkCoverageModal from "../../../components/modals/AddNetworkCoverageModal";
import DeleteNetworkCoverageModal from "../../../components/modals/DeleteNetworkCoverageModal";

const data = {
  country: { name: "Pakistan", id: 1, code: "PK" },
  Price: { starting: 42, previous: 10, id: 2 },
  flag: { image: "", id: 3 },
};

const AddLocalCountries = () => {
  const { uid } = useParams();
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState("name");
  const [imgUpdate, setImageUpdated] = useState("");
  const [online, setOnline] = useState(navigator.onLine);
  const startingPriceInput = useRef(null);
  const previousPriceInput = useRef(null);
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedPlanType, setSelectedPlanType] = useState("");
  const [planTypeData, setPlanTypeData] = useState([]);
  const [networkCoverages, setNetworkCoverages] = useState([]);
  const [countryCoverages, setCountryCoverages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalPlanTypeLoading, setModalPlanTypeLoading] = useState(false);
  const [selectedNetworkCoverage, setSelectedNetworkCoverage] = useState("");
  const [networkCoverageDetails, setNetworkCoverageDetails] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteNetworkCoverageId, setDeleteNetworkCoverageId] = useState(null);
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;

  // Router
  const navigate = useNavigate();

  // Handle the save button
  const handleSave = (e, key) => {
    switch (key) {
      case "Flag Image":
        const file = e.target.files[0];
        if (file?.size > 1000000) {
          toast.error("Image Size Should be Less Then 1MB");
          // setCardImg(app_url + PkgDetail.cardImage)
        } else if (file) {
          let imgData = new FormData();
          imgData.append("uid", uid);
          imgData.append("slug", "local");
          imgData.append("countryImage", file);
          setLoading(true);
          axios
            .post(
              `${app_url}/api/country/update_country_region_imageById`,
              imgData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              toast.success(res.data.message);
              setImageUpdated(imgData);
              setLoading(false);
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                navigate("/");
              } else if (err.response?.status === 400) {
                toast.error(err.response?.data.message);
                setLoading(false);
              } else if (err.response?.status === 500) {
                setOnline(false);
                setLoading(false);
              } else {
                setOnline(navigator.onLine);
                setLoading(false);
              }
            });
        }
        break;
      case "Starting Price":
        setLoading(true);
        axios
          .post(
            app_url +
              `/api/country/update_country_or_region_sp_price_by_id?uid=${uid}&c_r_slug=${"local"}&s_p_slug=${"starting_price"}&price=${
                apiData[0]?.startingFrom
              }`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success(res.data.message);
            setLoading(false);
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              navigate("/");
            } else if (err.response?.status === 400) {
              toast.error(err.response?.data.message);
              setLoading(false);
            } else if (err.response?.status === 500) {
              setOnline(false);
              setLoading(false);
            } else {
              setOnline(navigator.onLine);
              setLoading(false);
            }
          });
        break;
      case "Previous Price":
        setLoading(true);
        axios
          .post(
            app_url +
              `/api/country/update_country_or_region_sp_price_by_id?uid=${uid}&c_r_slug=${"local"}&s_p_slug=${"previous_price"}&price=${
                apiData[0]?.previousPrice
              }`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success(res.data.message);
            setLoading(false);
          })
          .catch((err) => {
            if (err.response?.status === 401) {
              navigate("/");
            } else if (err.response?.status === 400) {
              toast.error(err.response?.data.message);
              setLoading(false);
            } else if (err.response?.status === 500) {
              setOnline(false);
              setLoading(false);
            } else {
              setOnline(navigator.onLine);
              setLoading(false);
            }
          });
      default:
        break;
    }
  };

  // Get the Selected Country
  useEffect(() => {
    if (uid) {
      setLoading(true);
      const url = app_url + `/api/country/getLocalCountryById?uid=${uid}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setApiData(res.data?.country);
          setCarriers(res?.data?.carriers);
          setCountryCoverages(res?.data?.coverages);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            navigate("/");
          } else if (err.response?.status === 400) {
            toast.error(err.response?.data.message);
            setLoading(false);
          } else if (err.response?.status === 500) {
            setOnline(false);
            setLoading(false);
          } else {
            setOnline(navigator.onLine);
            setLoading(false);
          }
        });
    }
  }, [uid, imgUpdate]);

  // console.log("apiData===========================>", apiData);
  // Focus on input
  useLayoutEffect(() => {
    if (edit === "Starting Price") {
      startingPriceInput.current.focus();
    } else if (edit === "Previous Price") {
      previousPriceInput.current.focus();
    }
  }, [edit]);

  let countryID = apiData[0]?.id;
  // console.log(apiData, "api data check");
  // console.log(countryID, "country id check");

  // function to set selected network: countryCode, planType and networkName
  const handleNetworkCoverageChange = (e) => {
    const selectedNetworkName = e.target.value;
    setSelectedNetworkCoverage(selectedNetworkName);

    let selectedCoverage = null;
    let selectedNetwork = null;

    networkCoverages.forEach((coverage) => {
      const network = coverage.networks.find(
        (net) => net.name === selectedNetworkName
      );
      if (network) {
        selectedCoverage = coverage;
        selectedNetwork = network;
      }
    });

    if (selectedCoverage && selectedNetwork) {
      setNetworkCoverageDetails({
        networkName: selectedNetworkName,
        country_code: selectedCoverage.name,
        networkTypes: selectedNetwork.types,
      });
    }
  };

  // console.log(
  //   networkCoverageDetails,
  //   "selected Network Coverage Details check"
  // );

  const getPlanType = () => {
    setModalPlanTypeLoading(true);
    const url =
      app_url +
      `/api/country/getCoveragesTypeFromCarrierProvider?carrierProviderId=${
        carriers[0]?.id
      }&slug=${"local"}&countryCode=${
        apiData[0]?.countryCode
      }&regionSlug=${""}`;
    // console.log("Plan Type Requeast Url:", url);
    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setPlanTypeData(res?.data?.plans);
        // console.log(res?.data?.plans, "plan type data check");
        setModalPlanTypeLoading(false);
      })
      .catch((err) => {
        setModalPlanTypeLoading(false);
        if (err.response) {
          const { status, data } = err.response;
          if (status === 401) {
            navigate("/");
          } else if (status === 400) {
            toast.error(data?.message);
          } else if (status === 500) {
            toast.error(data?.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  const getNetworkCoverage = (selectedPlanType) => {
    setModalLoading(true);
    const url =
      app_url +
      `/api/country/getCoveragesFromCarrierProvider?carrierProviderId=${
        carriers[0]?.id
      }&slug=${"local"}&countryCode=${
        apiData[0]?.countryCode
      }&plan_type=${selectedPlanType}&regionSlug=${""}`;
    // console.log("Request URL:", url);
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setNetworkCoverages(res?.data?.coverages);
        setModalLoading(false);
      })
      .catch((err) => {
        setModalLoading(false);
        if (err.response) {
          const { status, data } = err.response;
          if (status === 401) {
            navigate("/");
          } else if (status === 400) {
            toast.error(data?.message);
          } else if (status === 500) {
            toast.error(data?.message);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  // console.log(modalPlanTypeLoading, "check modal loading");

  // Function to handle modal close
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to handle modal open
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCarrier("");
    setSelectedPlanType("");
    setNetworkCoverages([]);
    setSelectedNetworkCoverage("");
    setNetworkCoverageDetails(null);
  };

  const confirmDelete = () => {
    axios
      .delete(
        `${app_url}/api/country/remove_nc_by_id?id=${deleteNetworkCoverageId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        setShowDeleteModal(false);
        window.location.reload();
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/");
        } else if (error.response?.status === 400) {
          toast.error(error.response?.data.message);
        } else if (error.response?.status === 500) {
          toast.error(error.response?.data.message);
        } else {
          toast.error("Network error. Please check your internet connection.");
        }
      });
  };

  const handleDelete = (id) => {
    // console.log("Deleting ID:", id);
    setDeleteNetworkCoverageId(id);
    setShowDeleteModal(true);
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
  };

  // console.log(networkCoverages, "netwroks data check");

  return (
    <section className="wrapper d-flex align-content-center">
      <div className="row w-100 justify-content-center align-items-center">
        {loading ? (
          <div className="bg-transparent d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="col-lg-12 col-xl-8 col-md-10 col-sm-12">
              <div className="bg-white rounded p-4">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label for={data.country.id} className="form-label">
                          Country Name
                        </label>
                        <div className="mb-3 ps-0 form-check position-relative w-100 h-100">
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id={data.country.id}
                            aria-describedby="countryHelp"
                            value={apiData[0]?.name}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 ">
                        <label for={data.country.code} className="form-label">
                          Country Code
                        </label>
                        <div className="mb-3 ps-0 form-check position-relative w-100 h-100">
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            id={data.country.code}
                            value={apiData[0]?.countryCode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 ">
                        <label for={data.country.code} className="form-label">
                          Starting Price
                        </label>
                        <div className="mb-3 ps-0 form-check position-relative w-100 h-100">
                          <input
                            ref={startingPriceInput}
                            readOnly={edit !== "Starting Price"}
                            type="number"
                            onChange={(e) => {
                              const updatedDetails = apiData.map((item) => {
                                // Update only the startingFrom property
                                return {
                                  ...item,
                                  startingFrom: e.target.value,
                                };
                              });
                              setApiData(updatedDetails);
                            }}
                            className="form-control"
                            id={data.Price.starting}
                            value={apiData[0]?.startingFrom}
                            autoFocus={edit === "Starting Price"}
                          />

                          {edit === "Starting Price" ? (
                            <button
                              type="button"
                              style={{ position: "absolute", top: 0, right: 0 }}
                              onClick={() => {
                                handleSave("", edit);
                                setEdit("");
                              }}
                              className="btn py-1 btn-success h-100 rounded px-3 position-absolute"
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              type="button"
                              style={{ position: "absolute", top: 0, right: 0 }}
                              onClick={() => setEdit("Starting Price")}
                              className="btn py-1 btn-danger h-100 rounded px-3 position-absolute"
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3 ">
                        <label for={data.country.code} className="form-label">
                          Previous Price
                        </label>
                        <div className="mb-3 ps-0 form-check position-relative w-100 h-100">
                          <input
                            ref={previousPriceInput}
                            readOnly={edit !== "Previous Price"}
                            type="number"
                            className="form-control"
                            id={data.Price.previous}
                            value={apiData[0]?.previousPrice}
                            onChange={(e) => {
                              const updatedDetails = apiData.map((item) => {
                                // Update only the startingFrom property
                                return {
                                  ...item,
                                  previousPrice: e.target.value,
                                };
                              });
                              setApiData(updatedDetails);
                            }}
                          />
                          {edit === "Previous Price" ? (
                            <button
                              type="button"
                              onClick={() => {
                                handleSave("", edit);
                                setEdit("");
                              }}
                              style={{ position: "absolute", top: 0, right: 0 }}
                              className="btn py-1 btn-success h-100 rounded px-3 position-absolute"
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => setEdit("Previous Price")}
                              style={{ position: "absolute", top: 0, right: 0 }}
                              className="btn py-1 btn-danger h-100 rounded px-3 position-absolute"
                            >
                              Edit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cus-sm">
                        <label for="" className="w-100 mb-2">
                          Country Image:
                        </label>
                        <input
                          type="file"
                          name=""
                          id="chnageImg"
                          accept="image/png"
                          onChange={(e) => handleSave(e, "Flag Image")}
                          className="loginBannerApp"
                          style={{ display: "none" }}
                        />
                        <label
                          for="chnageImg"
                          id="loginBannerApp.png"
                          className="form-label card-image-p"
                        >
                          <i
                            className="bi bi-cloud-upload"
                            style={{ fontSize: "21px" }}
                          ></i>
                          <p className="m-0 ms-3">Change Country Image</p>
                        </label>
                        <div className="d-flex flex-wrap mt-3">
                          <img
                            src={app_url + apiData[0]?.countryImage}
                            width="180"
                            height="248"
                            style={{ objectFit: "cover" }}
                            className="rounded"
                          />
                          <ul className="img-bullit mt-3">
                            <li>Image size should be less then 1MB</li>
                            <li>
                              Image resolution should be 510 x 620 (Recommended)
                            </li>
                            <li>Image format should be png (Recommended)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <hr
                  className="border-top-2 border-bottom-2"
                  style={{ padding: "8px" }}
                />
                {countryCoverages?.length === 0 ? (
                  <div>No Data Found</div>
                ) : (
                  <table className="table table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>Country</th>
                        <th>Plan Type</th>
                        <th>Network Name</th>
                        <th>Network Type</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {console.log(
                        countryCoverages,
                        "country coverage value check"
                      )} */}
                      {countryCoverages.map((coverage, index) => (
                        <React.Fragment key={index}>
                          {coverage.networks?.map((item, networkIndex) => (
                            <tr key={networkIndex}>
                              {networkIndex === 0 && (
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                  rowSpan={coverage.networks.length}
                                >
                                  {coverage.country_code}
                                </td>
                              )}
                              <td>{item.plan_type}</td>
                              <td>{item.network_name}</td>
                              <td>
                                {JSON.parse(item.network_types).join(", ")}
                              </td>
                              <td style={{ color: "red" }}>
                                <i
                                  className="bi bi-trash text-danger fs-5 d-flex"
                                  onClick={() => handleDelete(item.id)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                )}
                <div
                  style={{
                    marginTop: "2em",
                    justifyContent: "right",
                    display: "flex",
                  }}
                >
                  <button className="btn card-btn" onClick={handleOpenModal}>
                    Add Network Coverage
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <AddNetworkCoverageModal
        setSelectedCarrier={setSelectedCarrier}
        carriers={carriers}
        selectedCarrier={selectedCarrier}
        setSelectedPlanType={setSelectedPlanType}
        getNetworkCoverage={getNetworkCoverage}
        selectedPlanType={selectedPlanType}
        networkCoverages={networkCoverages}
        show={showModal}
        onHide={handleCloseModal}
        loading={modalLoading}
        selectedNetworkCoverage={selectedNetworkCoverage}
        setSelectedNetworkCoverage={setSelectedNetworkCoverage}
        getPlanType={getPlanType}
        planTypeData={planTypeData}
        modalPlanTypeLoading={modalPlanTypeLoading}
        handleNetworkCoverageChange={handleNetworkCoverageChange}
        countryID={countryID}
        networkCoverageDetails={networkCoverageDetails}
      />
      <DeleteNetworkCoverageModal
        show={showDeleteModal}
        onHide={cancelDelete}
        onConfirm={confirmDelete}
      />
    </section>
  );
};

export default AddLocalCountries;
