import { useContext, useState } from "react";
import { NotificationContext } from "./NotificationContext";

export const NotificationProvider = ({ children }) => {
  const [selectedNotification, setSelectedNotification] = useState(null);

  return (
    <NotificationContext.Provider
      value={{ selectedNotification, setSelectedNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
