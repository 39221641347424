import React, { Suspense, lazy } from "react";
import "./css/styles.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CategoryScale } from "chart.js";
import { Chart } from "chart.js/auto";
import ViewTemplate from "./pages/notifications/[slug]";
import UpdateRegion from "./pages/regions/[slug]/UpdateRegion";
import Global from "./pages/global/Global";
import AddRegionalPackages from "./pages/packages/AddRegionalPackages";
import AddGlobalPackages from "./pages/packages/AddGlobalPackage";
import "./App.css";
import ProductUpdatesDetail from "./pages/productUpdates/[slug]";
import ProductUpdates from "./pages/productUpdates/ProductUpdates";
import AppNotifications from "./pages/app-notifications/app-notifications";
import Campaigns from "./pages/notifications/campaigns/Campaigns";
import { NotificationProvider } from "./context/NotificationProvider";
import AddLocalCountries from "./pages/local_countries/[slug].js/AddLocalCountries";
import ESims from "./pages/eSims/ESims";
const Login = lazy(() => import("./components/auth/Login"));
const Order = lazy(() => import("./pages/order/Order"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const OrderListing = lazy(() => import("./pages/order/[slug]"));
const Routeprivate = lazy(() => import("./store/routeprivate"));
const Packages = lazy(() => import("./pages/packages/Packages"));
const PackageDetail = lazy(() => import("./pages/packages/[slug]"));
const AddPackages = lazy(() => import("./pages/packages/AddPackages"));
const Customers = lazy(() => import("./pages/customers/Customers"));
const LocalCountries = lazy(() =>
  import("./pages/local_countries/LocalCountries")
);
const Promo = lazy(() => import("./pages/promo/Promo"));
const CreatePromo = lazy(() => import("./pages/promo/CreatePromo"));
const Region = lazy(() => import("./pages/regions/Region"));
const Notifications = lazy(() => import("./pages/notifications/Notifications"));
const Banners = lazy(() => import("./pages/banners/Banner"));
const CreateTemplate = lazy(() =>
  import("./pages/notifications/template/CreateTemplate")
);
const CreateCampaigns = lazy(() =>
  import("./pages/notifications/campaigns/CreateCampaign")
);

function App() {
  console.clear();

  {
    /* ================= Chart Import =================== */
  }
  Chart.register(CategoryScale);

  {
    /* ================= Auth =================== */
  }

  const dispatch = useDispatch();
  const getuser = localStorage.getItem("adminEtijwaal");
  const usertoken = JSON.parse(getuser);

  if (usertoken) {
    dispatch({ type: "LOGIN", payload: usertoken?.token });
  }

  return (
    <NotificationProvider>
      <div className="App" style={{ minHeight: "100vh" }}>
        {/* ================= Toast =================== */}
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{
            className: "",
            style: {
              fontSize: "16px",
              padding: "10px 20px",
            },
          }}
        />

        {/* ================= All Routeas =================== */}
        <BrowserRouter>
          <Suspense
            fallback={
              <div className="splash-screen">
                <img src="/assets/icons/Hourglass.gif" />
                <p className="mt-4">Please Wait..</p>
              </div>
            }
          >
            <Routes>
              {/* ================= Public Routes =================== */}
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />

              {/* ================= Private Routes =================== */}
              <Route element={<Routeprivate />}>
                {/* ================= Super Admin Routes =================== */}
                {usertoken?.user?.userType === "superadmin" ? (
                  <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/orders" element={<Order />} />
                    <Route path="/esims" element={<ESims />} />
                    <Route path="/orders/:id" element={<OrderListing />} />
                    <Route path="/packages" element={<Packages />} />
                    <Route path="/packages/:id" element={<PackageDetail />} />
                    <Route
                      path="/packages/addpackages"
                      element={<AddPackages />}
                    />
                    <Route path="/customers" element={<Customers />} />
                    <Route
                      path="/localcountries"
                      element={<LocalCountries />}
                    />
                    <Route path="/region" element={<Region />} />
                    <Route path="/promo" element={<Promo />} />
                    <Route
                      path="/promo/createpromo"
                      element={<CreatePromo />}
                    />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route
                      path="/notifications/:id"
                      element={<ViewTemplate />}
                    />
                    <Route path="/banner" element={<Banners />} />
                    <Route
                      path="/notifications/create-template"
                      element={<CreateTemplate />}
                    />
                    <Route path="/campaigns" element={<Campaigns />} />
                    <Route
                      path="/campaigns/create-campaign"
                      element={<CreateCampaigns />}
                    />
                    <Route
                      path="/localcountries/:uid"
                      element={<AddLocalCountries />}
                    />
                    <Route path="/region/:uid" element={<UpdateRegion />} />
                    <Route path="/global" element={<Global />} />
                    <Route
                      path="/packages/addRegionalPackages"
                      element={<AddRegionalPackages />}
                    />
                    <Route
                      path="/packages/addGlobalPackages"
                      element={<AddGlobalPackages />}
                    />
                    <Route
                      path="/productUpdates"
                      element={<ProductUpdates />}
                    />
                    <Route
                      path="/productUpdates/:uuid"
                      element={<ProductUpdatesDetail />}
                    />
                    <Route
                      path="app-notifications"
                      element={<AppNotifications />}
                    />
                  </>
                ) : // {/* ================= Admin Routes =================== */ }
                usertoken?.user?.userType === "admin" ? (
                  <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/orders" element={<Order />} />
                    <Route path="/esims" element={<ESims />} />
                    <Route path="/orders/:id" element={<OrderListing />} />
                    <Route path="/packages" element={<Packages />} />
                    <Route path="/packages/:id" element={<PackageDetail />} />
                    <Route
                      path="/packages/addpackages"
                      element={<AddPackages />}
                    />
                    <Route path="/customers" element={<Customers />} />
                    <Route
                      path="/localcountries"
                      element={<LocalCountries />}
                    />
                    <Route path="/region" element={<Region />} />
                    <Route path="/promo" element={<Promo />} />
                    <Route
                      path="/promo/createpromo"
                      element={<CreatePromo />}
                    />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route
                      path="/notifications/:id"
                      element={<ViewTemplate />}
                    />
                    <Route path="/banner" element={<Banners />} />
                    <Route
                      path="/notifications/create-template"
                      element={<CreateTemplate />}
                    />
                    <Route path="/campaigns" element={<Campaigns />} />
                    <Route
                      path="/campaigns/create-campaign"
                      element={<CreateCampaigns />}
                    />
                    <Route
                      path="/localcountries/:uid"
                      element={<AddLocalCountries />}
                    />
                    <Route path="/region/:uid" element={<UpdateRegion />} />
                    <Route path="/global" element={<Global />} />
                    <Route
                      path="/packages/addRegionalPackages"
                      element={<AddRegionalPackages />}
                    />
                    <Route
                      path="/packages/addGlobalPackages"
                      element={<AddGlobalPackages />}
                    />
                    <Route
                      path="/productUpdates"
                      element={<ProductUpdates />}
                    />
                    <Route
                      path="/productUpdates/:uuid"
                      element={<ProductUpdatesDetail />}
                    />
                    <Route
                      path="app-notifications"
                      element={<AppNotifications />}
                    />
                  </>
                ) : // {/* ================= Support Routes =================== */}
                usertoken?.user?.userType === "support" ? (
                  <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/orders" element={<Order />} />
                    <Route path="/orders/:id" element={<OrderListing />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route
                      path="app-notifications"
                      element={<AppNotifications />}
                    />
                  </>
                ) : // {/* ================= Billing Routes =================== */}
                usertoken?.user?.userType === "billing" ? (
                  <>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/orders" element={<Order />} />
                    <Route path="/orders/:id" element={<OrderListing />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route
                      path="app-notifications"
                      element={<AppNotifications />}
                    />
                  </>
                ) : (
                  <Route path="*" element={<Login />} />
                )}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </NotificationProvider>
  );
}

export default App;
