import React, { useState } from "react";
import flagImage from "../../../src/assets/images/displayFlagImage.png";
import { MdOutlineShare } from "react-icons/md";
import { MdOutlineSignalCellularAlt } from "react-icons/md";
import UsageModal from "../modals/UsageModal";
import { app_url } from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const EsimTable = ({ tabledata, isLoading }) => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState("");
  const [eSimDetail, seteSimDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const newArr = new Array(tabledata?.data?.result.length).fill(
    <p className="placeholder-glow">
      <span className="placeholder col-12"></span>
    </p>
  );

  // console.log(isLoading, "loading check");

  // console.log(tabledata, "esim table data check");

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleResendEmail = () => {
    setShowEmailModal(true);
  };

  const handleResendEmailClose = () => {
    setShowEmailModal(false);
  };

  const handleSendEmail = () => {
    // console.log(email, "email send");
    setShowEmailModal(false);
  };

  const fetcheSimDetail = (lineID) => {
    setLoading(true);
    axios
      .get(`${app_url}/api/line/getMyEsimByLineIdAport?lineId=${lineID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        seteSimDetail(res?.data);
        setLoading(false);
        // console.log(res, "response check");
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data?.message);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const skeleton = (
    <tbody>
      {newArr.map((item, index) => (
        <tr key={index}>
          <th scope="row" className="py-5 ps-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" style={{ textTransform: "none" }} className="py-5">
            {item}
          </th>
          <th scope="row" className="text-center py-5">
            {item}
          </th>
          {/* <th scope="row" className="text-center py-5">
            {item}
          </th> */}
          {/* <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th>
          <th scope="row" className="py-5">
            {item}
          </th> */}
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      <div className="table-responsive">
        <table className="table mt-4 table-tb">
          <thead className="">
            <tr className="rounded">
              <th className="tr-tt fw-bold ps-5" scope="col">
                eSIM
              </th>
              <th className="tr-tt fw-bold" scope="col">
                ICCID & ISSUE DATE
              </th>
              <th className="tr-tt fw-bold" scope="col">
                USER
              </th>
              <th className="tr-tt fw-bold" scope="col">
                Actions
              </th>
            </tr>
          </thead>

          {isLoading ? (
            skeleton
          ) : (
            <tbody>
              {tabledata?.data?.result.length == 0 ? (
                <tr>
                  <th className="py-4"></th>
                  <th className="py-4"></th>
                  <th className="py-4 align-middle">No Data Found</th>
                  <th className="py-4"></th>
                  <th className="py-4"></th>
                </tr>
              ) : (
                tabledata?.data?.result.map(
                  (
                    {
                      id,
                      iccid,
                      image,
                      first_name,
                      last_name,
                      packageName,
                      email,
                      type,
                      createdAt,
                    },
                    index
                  ) => (
                    <tr className="">
                      <th className="py-4 align-middle esim_flag ps-5">
                        <div className="d-flex gap-2 align-items-center">
                          <img
                            src={app_url + image}
                            alt="flag image"
                            loading="lazy"
                          />
                          <p className="mb-0">
                            {" "}
                            {packageName} <br />({type})
                          </p>
                        </div>
                      </th>
                      <th className="py-4 align-middle">
                        <div>
                          {" "}
                          {iccid} <br /> {new Date(createdAt).toLocaleString()}
                        </div>
                      </th>
                      <th className="py-4 align-middle">
                        <div>
                          {first_name} {last_name}
                          <br />
                          {email}
                        </div>
                      </th>
                      <th className="py-4 align-middle ">
                        <div className="d-flex gap-2">
                          <MdOutlineShare
                            size={28}
                            className="p-1 border border-dark rounded pointer"
                            style={{ borderRadius: "5px" }}
                            onClick={handleResendEmail}
                          />
                          <MdOutlineSignalCellularAlt
                            size={28}
                            className="p-1 border border-dark rounded pointer"
                            style={{ borderRadius: "5px" }}
                            onClick={() => {
                              handleShow();
                              fetcheSimDetail(id);
                            }}
                          />
                        </div>
                      </th>
                      {/* <th className="py-4 align-middle">No Data Found</th> */}
                    </tr>
                  )
                )
              )}
            </tbody>
          )}
        </table>
      </div>
      <UsageModal
        show={showModal}
        handleClose={handleClose}
        eSimDetail={eSimDetail}
        isLoading={loading}
      />
      {/* Modal */}
      {showEmailModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div className="modal fade show" style={{ display: "block" }}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Send Email</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleResendEmailClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="emailInput" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="emailInput"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSendEmail}
                  >
                    Send
                    {/* {emailLoading ? (
                      <span
                        className="spinner-border spinner-border-sm text-small ms-2"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )} */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EsimTable;
