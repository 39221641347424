import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { app_url } from "../../config";

const AddNetworkCoverageModal = ({
  show,
  onHide,
  setSelectedCarrier,
  carriers,
  selectedCarrier,
  setSelectedPlanType,
  getNetworkCoverage,
  selectedPlanType,
  networkCoverages,
  selectedNetworkCoverage,
  loading,
  getPlanType,
  planTypeData,
  modalPlanTypeLoading,
  handleNetworkCoverageChange,
  countryID,
  regionID,
  networkCoverageDetails,
  setSelectedNetworkCoverage,
}) => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const { pathname } = useLocation();
  const [slug, setSlug] = useState("");

  useEffect(() => {
    const extractSlug = () => {
      const parts = pathname.split("/");
      const localIndex = parts.findIndex((part) => part === "localCountries");
      const regionIndex = parts.findIndex((part) => part === "region");
      const globalIndex = parts.findIndex((part) => part === "global");
      if (localIndex !== -1) {
        setSlug("local");
      } else if (regionIndex !== -1) {
        setSlug("regional");
      } else if (globalIndex !== -1) {
        setSlug("global");
      }
    };
    extractSlug();
  }, [pathname]);
  // console.log(planTypeData, "plan type data check");

  const addNetworkCoverage = async () => {
    try {
      let requestData = {
        slug: slug,
        lc_id: null,
        rc_id: null,
        plan_type: selectedPlanType,
        coverage: networkCoverageDetails,
      };

      if (slug === "local") {
        requestData.lc_id = countryID;
      } else if (slug === "regional") {
        requestData.rc_id = regionID;
      }

      const response = await axios.post(
        `${app_url}/api/country/create_nc_for_lc_rc_gl`,

        requestData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Network coverage added successfully");
      window.location.reload();
      // console.log("Item added:", response.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
        } else if (error.response.status === 401) {
          toast.error(error.response.data.message);
          navigate("/");
        } else if (error.response.status === 500) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error("Network error. Please check your internet connection.");
      }
      console.error("Error:", error.response);
    }
  };

  if (!show) return null;

  return (
    <div>
      <div
        className={`modal fade ${show ? "show" : ""}`}
        style={{ display: show ? "block" : "none" }}
        id="scrollableModal"
        tabindex="-1"
        aria-labelledby="scrollableModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Network Coverage</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onHide}
              ></button>
            </div>
            <div className="modal-body">
              <div className="py-4">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Carrier Provider</label>
                      <div className="mb-3 ps-0 form-check position-relative w-100 h-100">
                        <select
                          className="form-select"
                          onChange={(e) => {
                            setSelectedCarrier(e.target.value);
                            getPlanType(e.target.value);
                          }}
                        >
                          <option value="" selected disabled hidden>
                            Select Carrier Provider
                          </option>
                          {carriers.length > 0 ? (
                            carriers.map((carrier) => (
                              <option key={carrier.id} value={carrier.provider}>
                                {carrier.carrierName} ({carrier.provider}-
                                {carrier.environment})
                              </option>
                            ))
                          ) : (
                            <option disabled>No Carrier Provider Found</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">Plan Type</label>
                      <div className="mb-3 ps-0 form-check position-relative w-100 h-100">
                        <select
                          disabled={modalPlanTypeLoading || !selectedCarrier}
                          onChange={(e) => {
                            setSelectedPlanType(e.target.value);
                            getNetworkCoverage(e.target.value);
                            setSelectedNetworkCoverage("");
                          }}
                          className="form-select"
                        >
                          <option value="" selected disabled hidden>
                            {modalPlanTypeLoading
                              ? "Loading..."
                              : "Select Plan Type"}
                          </option>
                          {!modalPlanTypeLoading &&
                            (planTypeData.length > 0 ? (
                              planTypeData.map((planType, index) => (
                                <option key={index} value={planType.type}>
                                  {planType?.type}
                                </option>
                              ))
                            ) : (
                              <option disabled>No Plan Type Found</option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Network Coverage</label>
                      <div className="mb-3 ps-0 form-check position-relative w-100 h-100">
                        <select
                          disabled={!selectedPlanType || loading}
                          className="form-select"
                          value={selectedNetworkCoverage}
                          onChange={handleNetworkCoverageChange}
                        >
                          <option value="" selected disabled hidden>
                            {loading ? "Loading..." : "Select Network Coverage"}
                          </option>
                          {!loading &&
                            (networkCoverages.length > 0 ? (
                              networkCoverages.map(
                                (coverage, coverageIndex) => (
                                  <React.Fragment key={coverageIndex}>
                                    {coverage.name ? (
                                      <option
                                        disabled
                                        key={`coverage-${coverageIndex}`}
                                        style={{
                                          backgroundColor: "lightgray",
                                          color: "white",
                                        }}
                                      >
                                        {coverage.name}
                                      </option>
                                    ) : null}
                                    {coverage.networks.map((network, index) => (
                                      <option
                                        key={`network-${index}`}
                                        value={network.name}
                                      >
                                        {network.name} (
                                        {network.types.join(", ")})
                                      </option>
                                    ))}
                                  </React.Fragment>
                                )
                              )
                            ) : (
                              <option disabled>
                                No Network Coverage Found
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className="mt-2 px-4 py-2 btn card-btn d-flex align-self-end"
                      disabled={!selectedNetworkCoverage}
                      onClick={addNetworkCoverage}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default AddNetworkCoverageModal;
