import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    tokenValue: JSON.parse(localStorage.getItem('adminEtijwaal'))?.token,
    IdValue: JSON.parse(localStorage.getItem('adminEtijwaal'))?.user?.id,
}
export const UserDeatilSlice = createSlice({
    name: 'User Deatil Slice',
    initialState,
    reducers: {
        // token: (state) => {
        //     state.tokenValue = 'aaaa'
        // }
    }
})
export const { } = UserDeatilSlice.actions
export default UserDeatilSlice.reducer
