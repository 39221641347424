import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import CampaignsTable from "../../../components/tables/CampaignsTable";
import axios from "axios";
import { app_url } from "../../../config";
import toast from "react-hot-toast";
import Error500 from "../../../components/500/Error500";
import Pagination from "../../../components/tables/Pagination";
import _ from "lodash";

const Campaigns = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [entries, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [online, setOnline] = useState(navigator.onLine);
  const itemsPerPage = entries;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchCampaigns = useCallback(
    _.debounce((search) => {
      setIsLoading(true);
      axios
        .get(
          `${app_url}/api/notification/getAllFcmCampaigns?search=${search}&numPerPage=${entries}&page=${
            currentPage - 1
          }`,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setCampaignData(res);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.res?.status === 401) {
            navigate("/");
          } else if (err?.res?.status === 400) {
            setErrMsg(err?.res?.data?.message);
            toast.error(err?.res?.data?.message);
          } else if (err?.res?.status === 404) {
            setOnline(navigator?.onLine);
            setErrMsg(err?.res?.data?.message);
            toast.error(err?.res?.data?.message);
          } else {
            setErrMsg(err?.res?.data?.message);
            toast.error(err?.res?.data?.message);
          }
        });
    }, 500), // 500ms debounce
    [entries, currentPage, token]
  );

  useEffect(() => {
    fetchCampaigns(searchQuery);
  }, [currentPage, searchQuery, entries, fetchCampaigns]);

  if (!online) {
    return <Error500 message={errMsg} />;
  }

  return (
    <>
      <div className="wrapper">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
          <div className="d-flex align-items-center gap-2">
            <i
              className="bi bi-arrow-left-circle-fill fs-3 my-auto pointer"
              onClick={() => window.history.back()}
            ></i>
            <p className="heading" style={{ marginBottom: 0 }}>
              Campaigns
            </p>
          </div>
        </div>
        <section>
          <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center">
            <div className="d-flex gap-3 justify-content-end align-content-end">
              <input
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
                value={searchQuery}
                style={{ width: 300 }}
                className="form-control"
                type="search"
                placeholder="Search by Campaign Name"
                aria-label="Search"
              />
            </div>
            <div className="">
              <label htmlFor="filterCampaign" className="form-label fs-6">
                Show Campaigns:
              </label>
              <select
                value={entries}
                onChange={(e) => {
                  setEntries(e.target.value);
                  setCurrentPage(1);
                }}
                className="form-select"
                id="filterCampaign"
              >
                <option value={"10"}>10</option>
                <option value={"20"}>20</option>
                <option value={"30"}>30</option>
                <option value={"40"}>40</option>
                <option value={"50"}>50</option>
              </select>
            </div>
          </div>
          <div className="">
            <CampaignsTable
              campaignData={campaignData?.data}
              isLoading={isLoading}
            />
          </div>
          <div className="col-md-12">
            <Pagination
              tabledata={campaignData}
              itemsPerPage={itemsPerPage}
              totalPages={Math.ceil(
                campaignData?.data?.pagiantion?.dataCount / itemsPerPage
              )}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              indexOfFirstItem={indexOfFirstItem}
              indexOfLastItem={indexOfLastItem}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default Campaigns;
