import React from "react";

const EsimDetailsModal = ({ show, onClose, esimData }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = esimData.qrCode; // Assuming `qrCode` is the URL of the QR code image
    link.download = "qrcode.png"; // The name of the downloaded file
    link.click();
  };

  return (
    <div
      className={`modal fade ${show ? "show d-block" : ""}`}
      tabIndex="-1"
      style={{ backgroundColor: show ? "rgba(0, 0, 0, 0.5)" : "transparent" }}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">eSIM Details</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <table className="table table-striped">
              <tbody>
                {/* <tr>
                  <th>ID</th>
                  <td>{esimData.id}</td>
                </tr> */}
                <tr>
                  <th>ICCID</th>
                  <td>{esimData.iccid}</td>
                </tr>
                <tr>
                  <th>LPA</th>
                  <td>{esimData.lpa}</td>
                </tr>
                <tr>
                  <th>SMDP Address</th>
                  <td>{esimData.smdpAddress}</td>
                </tr>
                <tr>
                  <th>Activation Code</th>
                  <td>{esimData.activationCode}</td>
                </tr>
                {/* <tr>
                  <th>PUK Code</th>
                  <td>{esimData.pukCode}</td>
                </tr> */}
                <tr>
                  <th>QR Code</th>
                  <td>
                    <img
                      src={esimData.qrCode}
                      alt="QR Code"
                      style={{ width: "150px", height: "150px" }}
                    />
                    <br />
                    <button
                      className="btn btn-success mt-2"
                      onClick={handleDownload}
                      style={{
                        backgroundColor: "#F57C21",
                        color: "#fff",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      Download
                    </button>
                  </td>
                </tr>
                {/* <tr>
                  <th>Status</th>
                  <td>{esimData.status}</td>
                </tr>
                <tr>
                  <th>Bundles Bought</th>
                  <td>{esimData.bundlesBought}</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsimDetailsModal;
