import axios from "axios";
import React, { useCallback } from "react";
import { useState } from "react";
import { app_url } from "../../config";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Error500 from "../../components/500/Error500";
import debounce from "lodash/debounce";

const AddRegionalPackages = () => {
  const token = JSON.parse(localStorage.getItem("adminEtijwaal"))?.token;
  const [PackageLoader, setPackageLoader] = useState(false);
  const [RegionalCountryId, setRegionalCountryId] = useState("");
  const [networkIssue, setNetworkIssue] = useState(false);
  const [PackageDetail, setPackageDetail] = useState(null);
  const [ProviderSec, setProviderSec] = useState(true);
  const [ProviderData, setProviderData] = useState([]);
  const [CountrySec, setCountrySec] = useState(true);
  const [CountryData, setCountryData] = useState([]);
  const [PackageSec, setPackageSec] = useState(true);
  const [PackageData, setPackageData] = useState([]);
  const [PackageName, setPackageName] = useState("");
  const [ShortInfo, setShortInfo] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Recharge, setRecharge] = useState(0);
  const [Provider, setProvider] = useState("");
  const [CardImg, setCardImg] = useState(null);
  const [netPrice, setNetPrice] = useState("");
  const [isUnlimited, setIsUnlimited] = useState("");
  const [Country, setCountry] = useState("");
  const [Package, setPackage] = useState("");
  const [bgColor, setbgColor] = useState("");
  const [Error, setError] = useState(false);
  const [DataMb, setDataMb] = useState("");
  const [failed, setFailed] = useState("");
  const [Price, setPrice] = useState("");
  const [Day, setDay] = useState("");
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [info, setInfo] = useState(null);
  const [isKycVerify, setIsKycVerify] = useState(0);
  const [otherInfo, setOtherInfo] = useState(null);
  const [planType, setPlanType] = useState(null);
  const [selectPlanTypeSec, setSelectPlanTypeSec] = useState(true);
  const [selectPlanType, setSelectPlanType] = useState("");
  const [selectPlanTypeData, setSelectPlanTypeData] = useState([]);
  const [selectPlanTypeLoader, setSelectPlanTypeLoader] = useState(false);
  const [voice, setVoice] = useState("");
  const [includeVoice, setIncludeVoice] = useState("");
  const [text, setText] = useState("");
  const [includeText, setIncludeText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const navigate = useNavigate();

  {
    /* ================= Select Provider =================== */
  }
  const selectprovider = (e) => {
    setPackageData([]);
    setDataMb("");
    setVoice("");
    setText("");
    setIncludeVoice("");
    setIncludeText("");
    setPrice("");
    setDay("");
    setNetPrice("");
    setIsUnlimited("");
    setPackageSec(true);
    setPackageData([]);
    setRegionalCountryId("");
    setPackage("");
    setCountry("");
    setPackageName("");
    setShortInfo("");
    setCountrySec(false);
    setSelectPlanTypeSec(true);
    setInfo(null);
    setIsKycVerify(0);
    setOtherInfo(null);
    setPlanType(null);
    setRecharge(0);
    setProvider(e.target.value);
  };

  {
    /* ================= Select Country =================== */
  }

  const getPackages = (e, page = 1) => {
    const updatedPlanType = e ? e.target.value : selectPlanType; // Fallback to existing planType if e is null
    setSelectPlanType(updatedPlanType); // state update with the new value
    setPackage("");
    setPackageSec(true);
    setDataMb("");
    setText("");
    setPrice("");
    setDay("");
    setNetPrice("");
    setIsUnlimited("");
    setPrice("");
    setDay("");
    setNetPrice("");
    setIsUnlimited("");
    setPrice("");
    setPackageName("");
    setShortInfo("");
    setNetPrice("");
    setIsUnlimited("");
    setDay("");
    setPackageLoader(true);
    setbgColor("");
    setCardImg(null);
    setInfo(null);
    setIsKycVerify(0);
    setOtherInfo(null);
    setPlanType(null);
    setRecharge(0);

    {
      /* ================= Package Api =================== */
    }
    // setLoadingCountry(true);
    axios
      .get(
        `${app_url}/api/package/getPackagesFromCarrierProvider?carrierProviderId=${Provider}&countryCode=&plan_type=${updatedPlanType}&regionSlug=${RegionalCountryId.regionSlug}&slug=regional`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (page === 1) {
          setPackageData(res.data.packages);
        } else {
          setPackageData((prevData) => [...prevData, ...res.data.packages]);
        }
        setRecharge(res?.data?.rechargeability);
        setLoadingCountry(false);
        setInfo(res?.data?.info);
        setIsKycVerify(res?.data?.is_kyc_verify);
        setOtherInfo(res?.data?.other_info);
        setPlanType(res?.data?.plan_type);
        setPackageSec(false);
        setPackageLoader(false);

        // Storing lastPage and current page in the state
        setLastPage(res?.data?.meta?.lastPage);
        setCurrentPage(page);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data.message);
          setLoadingCountry(false);
          setLoading(false);
        } else {
          setNetworkIssue(true);
          setFailed(err.message);
          setLoading(false);
          setLoadingCountry(false);
        }
      });
  };
  // console.log("RegionalCountryId =============>", RegionalCountryId);

  const getPlanType = (e) => {
    setCountry(e.target.value);
    const localcountryidFind = CountryData?.data?.regions?.find(
      (item) => item.regionSlug === e.target.value
    );
    if (localcountryidFind) {
      setRegionalCountryId(localcountryidFind);
    }
    setPackageSec(true);
    setPackage("");
    setSelectPlanType("");
    setPackageData([]);
    setSelectPlanTypeData([]);
    setSelectPlanTypeLoader(true);
    setSelectPlanTypeSec(true);
    {
      /* ================= Paln Type Api =================== */
    }
    axios
      .get(
        `${app_url}/api/package/getPlanTypeFromCarrierProviderAndSlug?carrierProviderId=${Provider}&slug=regional&countryCode=&regionSlug=${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectPlanTypeData(res);
        setSelectPlanTypeLoader(false);
        setSelectPlanTypeSec(false);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data.message);
          setSelectPlanTypeLoader(false);
        } else {
          setNetworkIssue(true);
          setFailed(err.message);
          setSelectPlanTypeLoader(false);
        }
      });
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;

    // Check if "See More" is selected
    if (selectedValue === "seeMore") {
      if (currentPage < lastPage) {
        getPackages(null, currentPage + 1);
        // console.log("Fetching more packages...");
      }
    } else {
      // Handle other selections
      selectPackage(e);
    }
  };

  {
    /* ================= Select Package =================== */
  }
  const selectPackage = (e) => {
    setPackage(e.target.value);
    const desiredObject = PackageData?.find(
      (item) => item.id === e.target.value
    );
    if (desiredObject) {
      // console.log(desiredObject)
      setDataMb(desiredObject.data);
      setVoice(desiredObject.voice);
      setIncludeVoice(desiredObject.includeVoice);
      setText(desiredObject.text);
      setIncludeText(desiredObject.includeText);
      setDay(desiredObject.day);
      setNetPrice(desiredObject.net_price);
      setIsUnlimited(desiredObject.is_unlimited);
      setPrice(desiredObject.price);
      setPackageDetail(desiredObject);
    }
  };

  const [results, setResults] = useState(null);

  const fetchPackages = async (name) => {
    try {
      const response = await axios.get(
        `${app_url}/api/package/searchActiveAndNonExpiredPackageNameByPackageName?packageName=${name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResults(response?.data?.available);
      setError("");
      // console.log(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
    }
  };

  const debouncedFetchPackages = useCallback(debounce(fetchPackages, 500), []);

  useEffect(() => {
    if (PackageName.length >= 10) {
      debouncedFetchPackages(PackageName);
    }
  }, [PackageName, debouncedFetchPackages]);

  const handlePackageNameChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    setPackageName(value);
    if (!value) {
      setError("Enter Package Name *");
    } else {
      setError("");
    }
  };

  {
    /* ================= Provider Api =================== */
  }

  useEffect(() => {
    axios
      .get(`${app_url}/api/package/getAllActiveCarrierProvider`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProviderSec(false);
        setProviderData(res);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.response?.data.message);
          setLoading(false);
        } else {
          setNetworkIssue(true);
          setFailed(err.message);
          setLoading(false);
        }
      });
  }, []);

  {
    /* ================= Country Api =================== */
  }

  useEffect(() => {
    axios
      .get(`${app_url}/api/package/getAllLocalsAndRegions?slug=regional`, {
        headers: {
          "Content-Type": "application/json",
          "Content-type": "multipart/form-date",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res, "kkk")
        setCountryData(res);
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate("/");
        } else if (err.response?.status === 400) {
          toast.error(err.message);
        } else {
          setNetworkIssue(true);
          setFailed(err.message);
        }
      });
  }, []);

  const AddPackage = (e) => {
    e.preventDefault();
    if (
      PackageName === "" ||
      Provider === "" ||
      Package === "" ||
      !PackageDetail?.title ||
      !PackageDetail?.data ||
      !PackageDetail?.amount ||
      !Price ||
      !PackageDetail?.day ||
      !PackageDetail?.net_price ||
      RegionalCountryId === "" ||
      ShortInfo === "" ||
      bgColor === "" ||
      CardImg === "" ||
      CardImg?.size > 30000
    ) {
      setError(true);
    } else {
      setLoading(true);
      const formData = new FormData();
      // Append data to the FormData object
      formData.append("packageName", PackageName);
      formData.append("carrierProviderId", Provider);
      formData.append("carrierPackageId", Package);
      formData.append("title", PackageDetail?.title);
      formData.append("includeData", PackageDetail?.data);
      formData.append("dataMB", PackageDetail?.amount);
      formData.append("includeVoice", PackageDetail?.includeVoice);
      formData.append("voice", PackageDetail?.voice);
      formData.append("includeText", PackageDetail?.includeText);
      formData.append("text", PackageDetail?.text);
      formData.append("price", Price);
      formData.append("day", PackageDetail?.day);
      formData.append("type", "regional");
      formData.append("regionalCountriesId", RegionalCountryId.id);
      formData.append("shortInfo", ShortInfo);
      formData.append("rechargeability", Recharge);
      formData.append("packageCardColor", bgColor);
      formData.append("cardImage", CardImg);
      formData.append("netPrice", PackageDetail?.net_price);
      formData.append("is_kyc_verify", isKycVerify);
      formData.append(
        "package_info",
        info && info[0] !== "" ? JSON.stringify(info) : null
      );
      formData.append(
        "other_info",
        otherInfo ? otherInfo : PackageDetail?.short_info
      );
      formData.append("plan_type", planType);
      formData.append(
        "is_unlimited",
        PackageDetail?.is_unlimited === false ? 0 : 1
      );
      axios
        .post(`${app_url}/api/package/registerNewPackage`, formData, {
          headers: {
            "Content-Type": "application/json",
            "Content-type": "multipart/form-date",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProvider("");
          setCountry("");
          setPackage("");
          setPackageName("");
          setShortInfo("");
          setbgColor("");
          setDataMb("");
          setVoice("");
          setText("");
          setIncludeVoice("");
          setIncludeText("");
          setPrice("");
          setDay("");
          setNetPrice("");
          setIsUnlimited("");
          setCardImg(null);
          setLoading(false);
          setError(false);
          setCountrySec(true);
          setPackageSec(true);
          // setCountryData(res);
          setInfo(null);
          setIsKycVerify(0);
          setOtherInfo(null);
          setPlanType(null);
          setRecharge(0);
          toast.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, [500]);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            navigate("/");
          } else if (err.response?.status === 400) {
            toast.error(err.response?.data.message);
            setLoading(false);
          } else {
            setNetworkIssue(true);
            setFailed(err.message);
            setLoading(false);
          }
        });
    }
  };

  if (networkIssue) {
    return <Error500 message={failed} />;
  }

  return (
    <>
      <section className="wrapper">
        <p className="heading">
          <i
            className="bi bi-arrow-left-circle-fill fs-3 me-3 my-auto pointer"
            onClick={() => window.history.back()}
          ></i>
          Add Regional Package
        </p>
        <div className="card">
          <div className="card-body">
            <form action="" onSubmit={AddPackage}>
              <div className="row add-pkg-form">
                {/* ================= Select Provider =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Select Provider</label>
                  <select
                    className="form-select"
                    value={Provider}
                    onChange={selectprovider}
                    disabled={ProviderSec}
                  >
                    <option value="" hidden>
                      Provider
                    </option>
                    {ProviderData?.data?.carriers?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {" "}
                        {item.carrierName} ({item.provider}-{item.environment})
                      </option>
                    ))}
                  </select>
                  {Error ? (
                    <small className="text-red">
                      {Provider === "" ? "Select Provider *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Select Region =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Select Region </label>
                  <select
                    className="form-select"
                    value={Country}
                    onChange={getPlanType}
                    disabled={CountrySec || loadingCountry}
                  >
                    {loadingCountry ? (
                      <option value="" hidden>
                        ...loading
                      </option>
                    ) : (
                      <>
                        <option value="" hidden>
                          Region
                        </option>
                        {CountryData?.data?.regions?.map((item, i) => (
                          <option key={i} value={item?.regionSlug}>
                            {item?.regionName}
                          </option>
                        ))}
                      </>
                    )}
                  </select>

                  {Error ? (
                    <small className="text-red">
                      {Country === "" ? "Select Country *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Select Plan Type =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Select Plan Type</label>
                  <select
                    className="form-select"
                    value={selectPlanType}
                    onChange={(e) => getPackages(e)}
                    disabled={selectPlanTypeSec}
                  >
                    {selectPlanTypeLoader ? (
                      <option value="" hidden>
                        ...loading
                      </option>
                    ) : (
                      <>
                        <option value="" hidden>
                          Plan Type
                        </option>
                        {selectPlanTypeData?.data?.plans?.length === 0 && (
                          <option value="" disabled>
                            No Plan Type Found
                          </option>
                        )}
                        {selectPlanTypeData?.data?.plans?.map((item, index) => (
                          <option key={index} value={item.op_id}>
                            {item.type}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                  {Error ? (
                    <small className="text-red">
                      {selectPlanType === "" ? "Select Plan Type *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Select Package =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Select Package</label>
                  <select
                    className="form-select"
                    value={Package}
                    // onChange={selectPackage}
                    onChange={handleSelectChange}
                    disabled={PackageSec}
                  >
                    {PackageLoader && (
                      <option value="" hidden>
                        ...loading
                      </option>
                    )}
                    <option value="" hidden>
                      Package
                    </option>
                    {PackageData?.length === 0 && (
                      <option value="" disabled>
                        {" "}
                        No Packages Found{" "}
                      </option>
                    )}
                    {PackageData?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                    {currentPage < lastPage && (
                      <option value="seeMore" className="text-primary">
                        See More
                      </option>
                    )}
                  </select>
                  {Error ? (
                    <small className="text-red">
                      {Package === "" ? "Select Package *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <hr className="my-5" />

                {/* ================= Data Display =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Data:</label>
                  <input
                    type="text"
                    disabled
                    name=""
                    placeholder="Ex: 10 MB"
                    id=""
                    value={DataMb === null ? "0 GB" : DataMb}
                    className="form-control"
                  />
                  {Error ? (
                    <small className="text-red">
                      {DataMb === "" ? "Select Package *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Voice Display =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Voice:</label>
                  <input
                    type="text"
                    disabled
                    name=""
                    placeholder="Ex: 10 Minutes"
                    id=""
                    value={includeVoice === null ? "0 Minute" : includeVoice}
                    className="form-control"
                  />
                  {Error ? (
                    <small className="text-red">
                      {includeVoice === "" ? "Select Package *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Text Display =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Text:</label>
                  <input
                    type="text"
                    disabled
                    name=""
                    placeholder="Ex: 10 SMS"
                    id=""
                    value={includeText === null ? "0 SMS" : includeText}
                    className="form-control"
                  />
                  {Error ? (
                    <small className="text-red">
                      {includeText === "" ? "Select Package *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Day Display =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Day:</label>
                  <input
                    type="text"
                    disabled
                    name=""
                    id=""
                    placeholder="Ex: 10 Days "
                    value={Day}
                    className="form-control"
                  />
                  {Error ? (
                    <small className="text-red">
                      {Day === "" ? "Select Package *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* Net Price */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Net Price: (US$)</label>
                  <input
                    type="text"
                    disabled
                    name=""
                    id=""
                    placeholder="Ex: 10 $ "
                    value={netPrice}
                    className="form-control"
                  />
                  {Error ? (
                    <small className="text-red">
                      {Day === "" ? "Select Package *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* Net Price */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Unlimited:</label>
                  {isUnlimited.length !== 0 && (
                    <input
                      type="text"
                      disabled
                      name=""
                      id=""
                      placeholder="Ex: Yes or No"
                      value={isUnlimited === true ? "Yes" : "No"}
                      className="form-control"
                    />
                  )}
                  {isUnlimited.length === 0 && (
                    <input
                      type="text"
                      disabled
                      name=""
                      id=""
                      placeholder="Ex: Yes or No"
                      value={isUnlimited}
                      className="form-control"
                    />
                  )}
                  {Error ? (
                    <small className="text-red">
                      {Day === "" ? "Select Package *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Price Display =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Sale Price: (US$)</label>
                  <input
                    type="text"
                    name=""
                    placeholder="Ex: 10 $ "
                    id=""
                    value={Price}
                    onChange={(e) => {
                      let pattern = /^(\d*\.?\d*)?$/;
                      const val = e.target.value;
                      if (!pattern.test(val)) return;
                      setPrice(e.target.value);
                    }}
                    className="form-control"
                  />
                  {Error ? (
                    <small className="text-red">
                      {Price === "" ? "Select Package *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                <hr className="my-5" />

                {/* ================= Package Name =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="packageName">Package Name:</label>
                  <div className="max-Alphabet-p">
                    <input
                      type="text"
                      id="packageName"
                      placeholder="Ex: UAE-7days-1gb"
                      value={PackageName}
                      onChange={handlePackageNameChange}
                      maxLength={30}
                      className="form-control"
                    />
                    <div className="max-Alphabet">
                      {PackageName.length} / 30
                    </div>
                  </div>
                  {Error && <small className="text-red">{Error}</small>}
                  {PackageName.length == 0 ? (
                    ""
                  ) : PackageName.length < 10 ? (
                    <div className="">
                      {/* <pre>{JSON.stringify(results, null, 2)}</pre> */}
                      <p className="text-danger mt-2">
                        Can't check availaibility
                      </p>
                    </div>
                  ) : results == true ? (
                    <div className="">
                      {/* <pre>{JSON.stringify(results, null, 2)}</pre> */}
                      <p className="text-success mt-2">
                        ({PackageName}) slot is available
                      </p>
                    </div>
                  ) : (
                    <div className="">
                      {/* <pre>{JSON.stringify(results, null, 2)}</pre> */}
                      <p className="text-danger mt-2">
                        This package ({PackageName}) already exist in our system
                      </p>
                    </div>
                  )}
                  {Error ? (
                    <small className="text-red">
                      {PackageName === "" ? "Enter Package Name *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Short Info =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Short Info:</label>
                  <div className="max-Alphabet-p">
                    <input
                      type="text"
                      value={ShortInfo}
                      maxLength={20}
                      onChange={(e) => setShortInfo(e.target.value)}
                      placeholder="Ex:United-Arab-Emirates"
                      className="form-control"
                      name=""
                      id=""
                    />
                    <div className="max-Alphabet">{ShortInfo.length} / 20</div>
                  </div>
                  {Error ? (
                    <small className="text-red">
                      {ShortInfo === "" ? "Enter Short Info *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Select Background Color =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Select Background Color</label>
                  <div class="input-group">
                    <select
                      name=""
                      onChange={(e) => setbgColor(e.target.value)}
                      id=""
                      className="form-select pe-4"
                    >
                      <option value="" hidden>
                        Background Colour
                      </option>
                      <option value="#28282B">Matte Black </option>
                      <option value="#FF5733">Orange</option>
                      <option value="#363062">Purple</option>
                      <option value="#525FE1">Violet</option>
                      <option value="#F9B572">Sand</option>
                      <option value="#025464">Cyan</option>
                      <option value="#47A992">Sea Green</option>
                      <option value="#146C94">Eminent Blue</option>
                      <option value="#19A7CE">Sky Blue</option>
                      <option value="#F45050">Soft Red</option>
                      <option value="#FFD24C">Yellow</option>
                      <option value="#388E3C">Green</option>
                      <option value="#4D606E">Grey</option>
                    </select>
                    <span className="input-group-text" id="basic-addon1">
                      <input
                        type="color"
                        value={bgColor}
                        className="type-clr"
                        name=""
                        id=""
                      />
                    </span>
                  </div>
                  {Error ? (
                    <small className="text-red">
                      {bgColor === "" ? "Select Background Colour *" : ""}
                    </small>
                  ) : (
                    ""
                  )}
                </div>

                {/* ================= Select Card Image =================== */}
                <div className="col-md-3 col-sm-6">
                  <label htmlFor="">Select Card Image</label>
                  {/* <input type="file" placeholder='United-Arab-Emirates' className='form-control' name="" accept="image/png" onChange={getimg} id="" /> */}
                  <input
                    type="file"
                    placeholder="United-Arab-Emirates"
                    className="form-control"
                    name=""
                    accept="image/png"
                    onChange={(e) => setCardImg(e.target.files[0])}
                    id=""
                  />
                  {Error ? (
                    <small className="text-red">
                      {CardImg === null
                        ? "Select Card Image *"
                        : CardImg?.size > 30000
                        ? "Image Size Should be Less Then 30kb *"
                        : CardImg === ""
                        ? "Select Card Image *"
                        : ""}
                    </small>
                  ) : (
                    ""
                  )}
                  <ul className="img-bullit ps-4 ">
                    <li>Image Size Should be Less Then 30kb</li>
                    <li>Image Resolution Should be 380 x 240 (Recommended)</li>
                  </ul>
                </div>

                <hr className="my-5" />
                {info && info[0] !== "" ? (
                  <div className="col-md-3 col-sm-6">
                    <h5 htmlFor="">Package Information</h5>
                    <ul className="ps-0">
                      {info.map((item, index) => (
                        <li key={index} style={{ listStyle: "none" }}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ""
                )}

                {otherInfo ? (
                  <div className="col-md-3 col-sm-6">
                    <div>
                      <h5>Other Information</h5>
                      <p>{otherInfo}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* ================= Select Background Color =================== */}
                {planType ? (
                  <div className="col-md-3 col-sm-6">
                    <div>
                      <h5>Plan Type</h5>
                      <p>{planType}</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-3 col-sm-6">
                  <div>
                    <h6>
                      KYC Verification:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {isKycVerify === 0 ? "NO" : "YES"}
                      </span>
                    </h6>
                    <h6>
                      Rechargeability:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {Recharge === 0 ? "NO" : "YES"}
                      </span>
                    </h6>
                  </div>
                </div>
                <hr className="my-5" />

                {/* ================= Form Submit =================== */}
                <div className="d-flex justify-content-center ">
                  <button
                    className="btn primary-btn w-50 py-2 d-flex align-items-center justify-content-center gap-2"
                    type="submit"
                  >
                    <span className="text-white fs-5">Submit</span>
                    {Loading ? (
                      <>
                        <div
                          className="text-white spinner-border"
                          role="status"
                        >
                          <span className="visually-hidden">Loading..</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddRegionalPackages;
