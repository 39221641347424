import { useState } from "react";
import { DeletePackageContext } from "./DeletePackageContext.js";

export const DeletePackageProvider = ({ children }) => {
  const [otp, setOtp] = useState("");
  const [description, setDescription] = useState("");

  return (
    <DeletePackageContext.Provider
      value={{
        otp,
        setOtp,
        description,
        setDescription,
      }}
    >
      {children}
    </DeletePackageContext.Provider>
  );
};
